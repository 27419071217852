import gsap from "gsap"
import styles from "./GameInstructions.module.scss"
import { useGame } from "../../store"
import { useEffect, useRef } from "react"
import { constants } from "../../constants"
function GameInstructions() {
    //stored values
    const instructionsStep = useGame((state) => state.instructionsStep)
    const instructions = useGame((state) => state.instructions)
    //refs
    const stepContainer = useRef()
    const instructionsLabels = [
        constants.LEVELS_LABEL,
        constants.SPEED_LABEL,
        constants.DIAMOND_LABEL,
        constants.POINTS_LABEL,
        constants.READY_LABEL,
        constants.GO_LABEL
    ]

    useEffect(() => {
        if (instructions)
            gsap.timeline()
                .to(stepContainer.current, { opacity: 1, duration: 0.5 })
                .to(stepContainer.current, { opacity: 0, delay: 20, duration: 0.3 })
    })

    return (
        <div className="gameInstructions">
            <div ref={stepContainer} className={styles.instructionsContainer}>
                {instructionsLabels[instructionsStep]}
            </div>
        </div>
    )
}

export default GameInstructions