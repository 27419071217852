import styles from "./LevelLabel.module.scss"
import { useGame } from "../../store"
import { useEffect, useRef } from "react"
import { constants } from "../../constants"
import gsap from "gsap"

function LevelLabel(){
    //stored values
    const levelIndex = useGame((state)=>state.levelIndex)
    const displayLevel = useGame((state)=>state.displayLevel)
    //refs
    const levelLabel = useRef()
    const labelTimeline =gsap.timeline()
    useEffect(()=>{
        if(displayLevel) {
            labelTimeline
            .to(levelLabel.current, {opacity: 1, duration: 0.3})
            .to(levelLabel.current, {opacity: 0, delay: 4, duration: 0.5})
        }
        if(levelIndex === 1 && displayLevel) {
            labelTimeline.restart()
        }

    }, [displayLevel, window, levelLabel, levelIndex])

    return (
        <div ref={levelLabel} className="levelLabel">
            <div className={styles.label}>
                {constants.LEVELS_LABELS[levelIndex]}
            </div>
        </div>
    )
}

export default LevelLabel