import { useGame } from "../../store"
import styles from "./ScoreCounter.module.scss"
import { useRef, useEffect } from "react"
import gsap from "gsap"

function ScoreCounter(){
    //stored values
    const score = useGame((state)=>state.score)
    const game = useGame((state)=>state.game)
    //refs
    const scoreCounter = useRef()
    useEffect(()=>{
        if(game){
            gsap.to(scoreCounter.current, {top: 10, duration: 0.5})
        }
        else
        {
            gsap.to(scoreCounter.current, {top: -200, duration: 0.5})
        }
    })
    return (
        <div ref={scoreCounter}className="scoreCounter">
            <span className={styles.text}>
                Score :<br/>
                {score}
            </span>
        </div>
    )
}

export default ScoreCounter