import styles from "./About.module.scss";
import BackToHomeButton from "../../components/backToHomeButton/BackToHomeButton"
import { useContent } from "../../store";
import { constants } from "../../constants";
import Socials from "../../components/socials/Socials";

function About(){
    const aboutContent = useContent((state) => state.aboutContent); 

    return (
        <div className="pageContainer">
            <div className={styles.container}>
                <div className="pageHeader">
                    <h1 className="pageTitle">{constants.ABOUT_TITLE}</h1>
                    <BackToHomeButton/>
                </div>
                <Socials/>
                <div
                    className="pageContent"
                    dangerouslySetInnerHTML={{__html: aboutContent}}
                />
            </div>
        </div>
    )
}
export default About