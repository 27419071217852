import styles from "./Home.module.scss";
import { useCamera } from "../../store";
function Home(){
    return (
        <div className="pageContainer pageContainer-home">
            <div>
            </div>
        </div>
    )
}
export default Home