import { useGLTF } from "@react-three/drei"
import sun from "./sun.glb"
import {Color} from "three"
import glsl from "babel-plugin-glsl/macro"
import { shaderMaterial } from "@react-three/drei"
import { extend } from "@react-three/fiber"
import { useGame } from "../../../store"
import { constants } from "../../../constants"

const SunShaderMaterial = shaderMaterial(
  //Uniform
  {
      uColor1: new Color(0.0, 0.0, 0.0),
      uColor2: new Color(0.0, 0.0, 0.0)
  },
  //Vertex shader
  glsl`
    varying vec2 vUv;

    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
    }
  `,
  //Fragment shader
  glsl`
    uniform vec3 uColor1;
    uniform vec3 uColor2;

    varying vec2 vUv;
    
    void main() {
      
      gl_FragColor = vec4(mix(uColor1, uColor2, vUv.x), 1.0);
    }
  `
)

extend({SunShaderMaterial})
function Sun(props) {
  //stored values
  const game = useGame((state) => state.game)
  const levelIndex = useGame((state) => state.levelIndex)
  //load gltf
  const { nodes, materials } = useGLTF(sun)
  return (
    <group {...props} dispose={null} scale={10}>
      <mesh geometry={nodes.Cylinder.geometry} material={materials["default"]} position={[0, 2.5, 0]} rotation={[0, -Math.PI / 2, -Math.PI / 2]} scale={[2.5, 0.1, 2.45]}>
        <sunShaderMaterial uColor1={game ? constants.SUN_GAME_MAIN_COLOR_1[levelIndex] : constants.SUN_COLOR_1} uColor2={game ? constants.SUN_GAME_MAIN_COLOR_2[levelIndex] : constants.SUN_COLOR_2}/>
      </mesh>
    </group>
  )
}

export default Sun

useGLTF.preload(sun)
