import { useRef, useEffect } from "react";
import { extend, useFrame } from "@react-three/fiber";
import {Color, MeshStandardMaterial, PlaneGeometry, Mesh} from "three";
import { Water } from "three/examples/jsm/objects/Water.js";
import { useGame, useControls } from "../../../store"
import { constants } from "../../../constants"

extend({ Water });

function Wireframe(){
  //refs
  const group = useRef()
  //stored values
  const game = useGame((state) => state.game)
  const levelIndex = useGame((state) => state.levelIndex)
  const speed = useControls((state) => state.speed)
  const wireframeGeometry = new PlaneGeometry( 300, 200, 30, 20)
  const wireframeMaterial = new MeshStandardMaterial({ color: new Color(game ? constants.WATER_GAME_COLOR[levelIndex] : constants.WATER_COLOR),  wireframe: true, emissive: new Color(game ? constants.WATER_GAME_COLOR[levelIndex] : constants.WATER_COLOR), emissiveIntensity: 1  })
  const wireframeMesh = new Mesh(wireframeGeometry, wireframeMaterial)
  wireframeMesh.rotation.x = Math.PI/2

  useEffect(() => {
        group.current.children[0] = new Mesh(wireframeGeometry, wireframeMaterial)
        group.current.children[0].position.z = -100
        group.current.children[0].rotation.x = Math.PI/2
        group.current.children[1] = new Mesh(wireframeGeometry, wireframeMaterial)
        group.current.children[1].position.z = 100
        group.current.children[1].rotation.x = Math.PI/2
  }, [wireframeGeometry, wireframeMaterial])

  useFrame(() => {
    for (const child of group.current.children){
      if (child.position.z < 200){
        child.position.z += speed / 2
      }
      else
      {
        child.position.z = -200
      }
    }
  })

  return (
    <group ref={group}>
    </group>
  )
}

function Ocean() {
  return (
    <group>
      <Wireframe />
      <mesh rotation-x={-Math.PI/2}>
        <planeGeometry args={[300, 200, 1, 1]}/>
        <meshStandardMaterial color={constants.WATER_BACKGROUND_COLOR} transparent={true} opacity={0.5}/>
      </mesh>
    </group>
  );
}

export default Ocean;