import {PerspectiveCamera} from "@react-three/drei"
import {useEffect, useRef} from "react"
import gsap from "gsap"
import { useCamera, useControls, useGame } from "../../../store"
import { constants } from "../../../constants"

function Camera(props){
    //stored functions
    const setIntroCompleted = useCamera((state) => state.setIntroCompleted)
    //stored values
    const introCompleted = useCamera((state) => state.introCompleted)
    const siteStarted = useControls((state) => state.siteStarted)
    const displayMenus = useControls((state) => state.displayMenus)
    const key = useControls((state) => state.keyPressed)
    const game = useGame((state) => state.game)
    const home = useControls((state) => state.home)
    //refs
    const camera = useRef()

    const terminatePreloader = () => {
        setIntroCompleted()
        displayMenus(true)
    }

    useEffect(() => {
        if(siteStarted){
            gsap.to(camera.current.position, {z: 7, duration: 20, onComplete: terminatePreloader})
            gsap.to(camera.current.position, {x: 0, duration: 20})
            gsap.to(camera.current.position, {y: 2, delay: 5, duration: 5})
        }
        if(!home){
            camera.current.position.z = 7
            camera.current.position.y = 2
            camera.current.position.x = 0
        }
        else
        {
            camera.current.position.z = 32
            camera.current.position.y = 4
            camera.current.position.x = -4
        }
    }, [setIntroCompleted, siteStarted])

    useEffect(() => {
        if(game){
            gsap.to(camera.current.position, {z: 9, y: 3, duration: 2})
        }
        if(!game && introCompleted){
            gsap.to(camera.current.position, {z: 7, y: 2, duration: 2})
        }
    }, [game, introCompleted])

    useEffect(() => {
        if(introCompleted){
            if(key === constants.LEFT){
                gsap.to(camera.current.position, {x: -1, duration: 0.5})
            }
            if(key === constants.RIGHT){
                gsap.to(camera.current.position, {x: 3, duration: 0.5})
            }
            if(key === constants.UP){
                gsap.to(camera.current.position, {z: 9, duration: 0.5})
            }
            if(key === constants.DOWN){
                gsap.to(camera.current.position, {z: 7, duration: 0.5})
            }
        }
    }, [key])
    
    return (
        <PerspectiveCamera
            {...props}
            ref={camera}
            aspect={1200 / 600}
            radius={(1200 + 600) / 4}
            fov={80}
            position-z={32}
            position-y={4}
            position-x={-4}
            makeDefault={true}
        />
    )
}

export default Camera