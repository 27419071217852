import { useCamera, useControls, usePopup, useGame } from "../../store"
import { useRef, useEffect } from "react"
import gsap from "gsap"
import styles from "./GraphicsControls.module.scss"
import { constants } from "../../constants"
import Popup from "../popup/Popup"

function GraphicsControls() {
    //stored values
    const bloom = useCamera((state) => state.bloom)
    const siteStarted = useControls((state) => state.siteStarted)
    const bloomPopup = usePopup((state) => state.bloomPopup)
    const game = useGame((state) => state.game)
    const menus = useControls((state) => state.menus)
    //stored functions
    const openBloomPopup = usePopup((state) => state.openBloomPopup)
    const setBloom = useCamera((state) => state.setBloom)
    const setDpr = useCamera((state) => state.setDpr)
    const closeBloomPopup = usePopup((state) => state.closeBloomPopup)
    //refs
    const graphicsControls = useRef()

    function setGraphics(value) {
        setDpr(value)
    }

    const validate = () => {
        setBloom(true)
        closeBloomPopup()
    }
    const decline = () => { closeBloomPopup() }

    const openPopup = () => { bloom ? setBloom(false) : openBloomPopup() }

    useEffect(() => {
        if (menus) gsap.to(graphicsControls.current, { right: 0, duration: 0.5 })
        if (!menus) gsap.to(graphicsControls.current, { right: -400, duration: 0.5 })
    })

    return (
        <>
            <div ref={graphicsControls} className="graphicsControls">
                <div className="graphicsContainer">
                    <h3 className={styles.title}>Graphics</h3><br />
                    <button className={styles.button} onClick={() => setGraphics(0.3)}>Low</button><button className={styles.button} onClick={() => setGraphics(1)}>Medium</button><button className={styles.button} onClick={() => setGraphics(1.2)}>High</button><button className={styles.button} onClick={openPopup}>Bloom</button>
                </div>
            </div>
            <Popup openPopup={bloomPopup} message={constants.MESSAGE} validate={validate} decline={decline} />
        </>
    )
}

export default GraphicsControls