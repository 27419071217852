import styles from "./MobileControls.module.scss"
import { useControls, useGame } from "../../store"
import { useRef, useEffect } from "react"
import { constants } from "../../constants"
import { faArrowRight, faArrowLeft, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import gsap from "gsap"

function MobileControls(props){
    //stored functions
    const setKey = useControls((state) => state.setKey); 
    const setHighSpeed = useControls((state) => state.setHighSpeed); 
    const setLowSpeed = useControls((state) => state.setLowSpeed);
    //stored values
    const instructions = useGame((state) => state.instructions)
    const game = useGame((state) => state.game)
    //refs
    const mobileControls = useRef()

    const accelerate = () => {
        setKey(constants.UP)
        setHighSpeed()
    }
    const descelerate = () => {
        setKey(constants.DOWN)
        setLowSpeed()
    }
    const turnLeft = () => {
        setKey(constants.LEFT)
    }
    const turnRight = () => {
        setKey(constants.RIGHT)
    }
    
    useEffect(() => {
        if (instructions)
            gsap.timeline()
                .to(mobileControls.current, { opacity: 1, duration: 0.5 })
                .to(mobileControls.current, { opacity: 0.01, delay: 20, duration: 0.3 })
    })

    return game ? (
        <div ref={mobileControls} className={props.className}>
            <span className={styles.label}>
                {constants.TOUCH_ZONE_LABEL}
            </span>
            <div onClick={turnLeft} className={`${styles.leftControl} ${styles.control}`}>
                <span className={styles.column}><FontAwesomeIcon icon={faArrowLeft} className={styles.icon}/></span>
            </div>
            <div onClick={turnRight} className={`${styles.rightControl} ${styles.control}`}>
                <span className={styles.column}><FontAwesomeIcon icon={faArrowRight} className={styles.icon}/></span>
            </div>
            <div onClick={accelerate} className={`${styles.topControl} ${styles.control}`}>
                <span className={styles.column}><FontAwesomeIcon icon={faArrowUp} className={styles.icon}/></span>
            </div>
            <div onClick={descelerate} className={`${styles.bottomControl} ${styles.control}`}>
                <span className={styles.column}><FontAwesomeIcon icon={faArrowDown} className={styles.icon}/></span>
            </div>
        </div>
    ) : null
}

export default MobileControls