import styles from "./GameEndingCountdown.module.scss"
import { useEffect, useState, useRef } from "react";
import { useGame } from "../../store";
import gsap from "gsap";

let countdown

function GameEndingCountdown(){
    //stored values
    const endingCountdown = useGame((state)=>state.endingCountdown)
    //refs
    const gameEndingCountdown = useRef()
    const [counter, setCounter] = useState(10);
    const startTimer = () => {
        if(counter > 0) countdown = setTimeout(() => setCounter(counter - 1), 1000);
    }

    useEffect(()=>{
        if(endingCountdown){
            startTimer()
            gsap.to(gameEndingCountdown.current, {bottom: 10, duration: 0.5})
        }
        else
        {
            gsap.to(gameEndingCountdown.current, {bottom: -100, duration: 0.5})
            clearTimeout(countdown)
            setCounter(10)
        }
    })

    return(
        <div ref={gameEndingCountdown} className="gameEndingCountdown">
            <span className={styles.text}>Game will end in : {counter}</span>
        </div>
    )
}

export default GameEndingCountdown