import {useFrame} from "@react-three/fiber"
import {useRef} from "react"
import {useControls} from "../../../../store";
import {useGame} from "../../../../store";
import { constants } from "../../../../constants";

function Material(){
    //stored values
    const game = useGame((state) => state.game)
    const levelIndex = useGame((state) => state.levelIndex)
    return (
        <>
            <meshBasicMaterial color={game ? constants.ROADLINES_GAME_COLOR[levelIndex] : constants.ROADLINES_COLOR}/>
        </>
    )
}

function SideRoadLine(props){
    return (
        <mesh rotation-x={-Math.PI/2} position-x={props.positionX} position-z={0} position-y={0.1}>
            <planeGeometry args={[0.1, 100]} />
            <Material />
        </mesh>
    )
}

function RoadLine(props){
    return (
        <>
            <mesh rotation-x={-Math.PI/2}>
                <planeGeometry args={props.args} />
                <Material />
            </mesh>
        </>
    )
}

function RoadLines(props){
    //stored values
    const speed = useControls((state) => state.speed)
    //refs
    const ref = useRef();
    useFrame(() => {
        for (let roadLine of ref.current.children){
            roadLine.position.z += speed
            if (roadLine.position.z > 50){
                roadLine.position.z = -50
            }
        }
    })
    return (
        <group>
            <group ref={ref} {...props} dispose={null}>
                {[...Array(10)].map((x, i) => {                
                    return(
                        <group position-z={i*10 - 50} key={i}>
                            <RoadLine args={[0.2, 1.5]}/>
                        </group>
                    )
                    }
                )}
            </group>
            <SideRoadLine positionX={2.2}/>
            <SideRoadLine positionX={-2.2}/>
        </group>
    )
}
export default RoadLines