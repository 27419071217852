import React from "react"
import { useGLTF } from "@react-three/drei"
import mountains from "./mountains.glb"

export function Mountains(props) {
  const { nodes, materials } = useGLTF(mountains)
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Cube.geometry} material={materials.Material} scale={[1, 0.05, 2.5]}>
        <meshStandardMaterial color="black"/>
      </mesh>
      <mesh geometry={nodes.Cube001.geometry} material={materials["Material.001"]} position={[-2.01, 0, 0]} scale={[1, 0.08, 2.5]}>
        <meshStandardMaterial color="black"/>
      </mesh>
    </group>
  )
}

useGLTF.preload(mountains)

export default Mountains