import styles from "./Socials.module.scss"
import Linkedin from "./linkedin.svg";
import Instagram from "./instagram.svg"
import { constants } from "../../constants";
import { ReactSVG } from 'react-svg'

function Socials(){
    return (
        <div className={styles.socials}>
            <a className={styles.link} href={constants.LINKEDIN} target="_blank">
                <ReactSVG className={styles.logo} src={Linkedin} alt={constants.LINKEDIN_ALT} />
            </a>
        </div>
    )
}

export default Socials