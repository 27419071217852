import Terrain from "./terrain/terrain"
import Buildings from "./buildings/Buildings"
function Town(props){
    return (
        <group group {...props} dispose={null}>
            <Terrain />
            <Buildings position-x={-8} position-z={-22}/>
            <Buildings position-x={-14} position-z={-15}/>
        </group>
    )
}
export default Town