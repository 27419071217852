import {useCallback} from "react"
import {useNavigate} from "react-router-dom"
import styles from "./BackToHomeButton.module.scss"

export default function BackToHomeButton(){
    const navigate = useNavigate()
    const handleOnClick = useCallback(() => navigate('/', {replace: true}), [navigate])
    return (
        <div className={styles.closeButtonContainer}>
            <button className={styles.closeButton} onClick={handleOnClick}>x</button>
        </div>
    )
}