import RoadLines from "./roadlines/Roadlines"
function Road(props){
    return (
        <group group {...props} dispose={null}>
            <RoadLines position-y={0.1}/>
            <mesh rotation-x={-Math.PI/2}>
                <planeGeometry args={[5, 150]} />
                <meshPhysicalMaterial color="grey" roughness={1} metalness={0}/>
            </mesh>
            <mesh rotation-x={-Math.PI/2} position-x={-2.5}>
                <boxGeometry args={[0.5, 150, 0.5]} />
                <meshPhysicalMaterial color="grey" roughness={1} metalness={0}/>
            </mesh>
        </group>
    )
}
export default Road