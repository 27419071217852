import gsap from "gsap"
import { useEffect, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faVolumeHigh, faVolumeOff } from '@fortawesome/free-solid-svg-icons'
import { useControls } from "../../store";
import styles from "./MobileSoundControl.scss"

function MobileSoundControl(props) {
    //stored values
    const menus = useControls((state) => state.menus)
    const radio = useControls((state) => state.radio)
    //stored functions
    const setRadio = useControls((state) => state.setRadio);
    //refs
    const mobileSoundControls = useRef()
    
    useEffect(()=>{
        if(menus) gsap.to(mobileSoundControls.current, {right: 0, duration: 0.5})
        if(!menus) gsap.to(mobileSoundControls.current, {right: -400, duration: 0.5})
    })



    return (
        <div className={props.className} ref={mobileSoundControls}>
            <div onClick={setRadio}>
                {radio ? (<FontAwesomeIcon icon={faVolumeOff} className={styles.icon}/>) 
                        :(<FontAwesomeIcon icon={faVolumeHigh} className={styles.icon}/>)} 
            </div>
        </div>
    )
}

export default MobileSoundControl