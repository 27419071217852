import {useFrame} from "@react-three/fiber"
import {useRef, useEffect} from "react"
import {useControls} from "../../../../store"
import {Color} from "three"
import {useGame} from "../../../../store"
import { constants } from "../../../../constants"

function Building(props){
    return (
        <>
            <mesh rotation-x={-Math.PI/2}>
                <boxGeometry args={props.args} />
                <meshBasicMaterial/>
            </mesh>
        </>
    )
}

function Buildings(props){
    //stored values
    const game = useGame((state) => state.game)
    const levelIndex = useGame((state) => state.levelIndex)
    const speed = useControls((state) => state.speed)

    const ref = useRef();

    const getRandomInt = (int) => {
        return Math.floor(Math.random() * int) + 2
    }
    const getRandomColor = () => {
        return new Color(Number(`0x${Math
            .floor(Math
                .random()*16777215)
                    .toString(16)}`))
    }

    
    
    useFrame(() => {
        for (let building of ref.current.children){
            if(game){
                building.children[0].material.color = new Color(constants.BUILDINGS_GAME_COLOR[levelIndex])
            }
            building.position.z += speed
            if (building.position.z > 50){
                //game ? building.children[0].material.color = new Color(constants.BUILDINGS_GAME_COLOR[levelIndex]) : getRandomColor()
                building.position.z = -50
            }
        }
    })

    useEffect(() => {    
        if(!game){
            for (let building of ref.current.children){
                    let buildingScaler = getRandomInt(2)
                    building.scale.y = buildingScaler
                    building.position.y = (constants.BUILDING_BASIC_HEIGHT*buildingScaler)/2
                    building.children[0].material.color = getRandomColor()
            }
        }
    }, [game]);

    return (
        <group ref={ref} {...props} dispose={null}>
            {[...Array(10)].map((color, i) => {                
                return(
                   <group position-z={i*10 - 50} key={i}>
                        <Building args={[3, 3, constants.BUILDING_BASIC_HEIGHT]}/>
                   </group>
                )
                }
            )}
        </group>
    )
}
export default Buildings