import create from 'zustand'

const useCamera = create((set) => ({
  cameraStarted: false,
  introCompleted: false,
  dpr: 1,
  bloom: false,
  setCameraStarted: () => set(() => ({
    cameraStarted: true
  })),
  setIntroCompleted: () => set(() => ({
    introCompleted: true
  })),
  setDpr: (value) => set(() => ({
    dpr: value
  })),
  setBloom: (value) => set((state) => ({
    bloom: value
  }))  
}))

export default useCamera