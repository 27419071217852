import {useFrame} from "@react-three/fiber"
import {useRef, useEffect, useState } from "react"
import { useControls, useGame } from "../../../store"
import {MeshStandardMaterial, Color} from "three"
import {  useGLTF } from "@react-three/drei"
import rock from "./rock.glb"
import diamond from "./diamond.glb"
import { constants } from "../../../constants"

let timer
function Game(props){
    //stored values
    const speed = useControls((state) => state.speed)
    const carSidePosition = useGame((state) => state.carSidePosition)
    const isHighSpeed = useControls((state) => state.isHighSpeed)
    const game = useGame((state) => state.game)
    const levelIndex = useGame((state) => state.levelIndex)
    const score = useGame((state) => state.score)

    //stored functions
    const setLevelIndex = useGame((state) => state.setLevelIndex)
    const setSpeed = useControls((state) => state.setSpeed)
    const setInstructionsStep = useGame((state) => state.setInstructionsStep)
    const setInstructions = useGame((state) => state.setInstructions)
    const setLevelDisplay = useGame((state) => state.setLevelDisplay)
    const setScore = useGame((state) => state.setScore)
    const endGame = useGame((state) => state.endGame)
    const displayEndingMessage = useGame((state) => state.displayEndingMessage)
    const displayEndingCountdown = useGame((state) => state.displayEndingCountdown)
    const setNavigation = useControls((state) => state.setNavigation)

    //refs
    const ref = useRef();

    //load diamond
    const diamondObject = useGLTF(diamond)
    const diamondGeometry = diamondObject?.scene?.children[0]?.geometry
    const diamondMaterial = new MeshStandardMaterial({ color: new Color(constants.DIAMOND_COLOR)})

    //timer
    const [counter, setCounter] = useState(380);

    //clearTimeout(this.timeoutID);
    const startTimer = () => {
        if(counter > 0) timer = setTimeout(() => setCounter(counter - 1), 1000);
    }

    function updateScore(value){
        setScore(score+(value*1127))
    }

    function resetDiamonds(){
        for (let [i, group] of ref.current.children.entries()){
            group.position.z = i*20 - 120
        }
    }

    useEffect(()=>{
        if(game) startTimer()
        if(counter === 379) setInstructions(true)
        if(counter === 376) setInstructionsStep(1)
        if(counter === 372) setInstructionsStep(2)
        if(counter === 368) setInstructionsStep(3)
        if(counter === 365) setInstructionsStep(4)
        if(counter === 362) setInstructionsStep(5)
        if(counter === 360) {
            setInstructions(false)
            setLevelDisplay(true)
        }
        if(counter === 345){
            setLevelIndex(1)
            isHighSpeed ? setSpeed(constants.INITSPEED * 2.2) : setSpeed(constants.INITSPEED * 1.1)
        }
        if(counter === 310){
            setLevelIndex(2)
            isHighSpeed ? setSpeed(constants.INITSPEED * 2.4) : setSpeed(constants.INITSPEED * 1.2)
        }
        if(counter === 295){
            setLevelIndex(3)
            isHighSpeed ? setSpeed(constants.INITSPEED * 2.6) : setSpeed(constants.INITSPEED * 1.3)
        }
        if(counter === 280){
            setLevelIndex(4)
            isHighSpeed ? setSpeed(constants.INITSPEED * 2.8) : setSpeed(constants.INITSPEED * 1.4)
        }
        if(counter === 265){
            setLevelIndex(5)
            isHighSpeed ? setSpeed(constants.INITSPEED * 3) : setSpeed(constants.INITSPEED * 1.5)
        }
        if(counter === 250){
            displayEndingCountdown(true)
        }
        if(counter === 240){
            endGame()
            displayEndingMessage(true)
            displayEndingCountdown(false)
            setSpeed(constants.INITSPEED)
            setLevelIndex(0)
            setLevelDisplay(false)
            resetDiamonds()
            setInstructions(false)
            /* setFinalScore(score)
            setScore(0) */
            setCounter(380)
            clearTimeout(timer);
            setInstructionsStep(0)
            setNavigation(true)
        }
    })

    useEffect(() => {
        for (let group of ref.current.children){
            group.children[0].geometry = diamondGeometry
            group.children[0].material = diamondMaterial
            let scale = 0.3
            group.children[0].scale.x = scale
            group.children[0].scale.y = scale
            group.children[0].scale.z = scale
            group.position.x = Math.round(Math.random())*2
            group.position.y = 1.6
        }
    }, [])

    useFrame(() => {
        if(game && counter < 360){
            for (let group of ref.current.children){
                group.children[0].material.color = new Color(constants.DIAMOND_GAME_COLOR[levelIndex])
                group.position.z += speed
                group.rotation.y += 0.01
                if (group.position.z > 50){
                    group.position.z = -50
                    group.position.x = Math.round(Math.random())*2
                }
                if ((group.position.x === 2 && (carSidePosition === constants.RIGHT)) || (group.position.x === 0 && (carSidePosition === constants.LEFT))){
                    if(isHighSpeed){
                        if((group.position.z > constants.CAR_BREAKING_POSITION + 2.5)){
                            group.position.x = Math.round(Math.random())*2
                            group.position.z = -50
                            updateScore(1*levelIndex+1)
                        }
                    }
                    if(!isHighSpeed){
                        if((group.position.z > constants.CAR_BREAKING_POSITION + 3.5)){
                            group.position.x = Math.round(Math.random())*2
                            group.position.z = -50
                            updateScore(2*levelIndex+1)
                        }
                    }
                }
            }
        }
    })
    
    return (
        <group ref={ref} {...props} dispose={null}>
            {[...Array(5)].map((color, i) => {             
                return(
                   <group position-z={i*20 - 120} key={i}>
                        <mesh>
                        </mesh>
                   </group>
                )
                }
            )}
        </group>
    )
}
export default Game


useGLTF.preload(rock)
useGLTF.preload(diamond)