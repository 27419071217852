import create from 'zustand'

const useLights = create((set) => ({
  carFrontLightsPositionZ: 0,
  carBackLightsPositionZ: -1,
  setCarFrontLightsPositionZ: (position) => set(() => ({ 
    carFrontLightsPositionZ: position
  })),
  setCarBackLightsPositionZ: (position) => set(() => ({
    carBackLightsPositionZ: position
  }))
}))

export default useLights