import Scene from "./components/scene/Scene"
import KeyboardEvents from "./components/utils/keyboardEvents"
import "./scss/App.scss"
import Nav from "./components/nav/Nav"
import GameTutorial from "./components/gameTutorial/GameTutorial"
import GraphicsControls from "./components/graphicsControls/GraphicsControls"
import Preloader from "./components/preloader/Preloader"
import RadioPlayer from "./components/radioPlayer/RadioPlayer"
import GameInstructions from "./components/gameInstructions/GameInstructions"
import LevelLabel from "./components/levelLabel/LevelLabel"
import ScoreCounter from "./components/scoreCounter/ScoreCounter"
import GameEndingCountdown from "./components/gameEndingCountdown/GameEndingCountdown"
import GameSummary from "./components/gameSummary/GameSummary"
import MobileSoundControl from "./components/mobileSoundControl/MobileSoundControl"
import MobileControls from "./components/mobileControls/MobileControls"
import getPagesContent from "./sdk/content"
import { useContent, useControls } from "./store"
import { useEffect } from "react"
import ReactGA from 'react-ga';

const TRACKING_ID = "G-JDV2435J9E";
ReactGA.initialize(TRACKING_ID);

function App() {
  //stored functions
  const setContactContent = useContent((state) => state.setContactContent)
  const setAboutContent = useContent((state) => state.setAboutContent)
  const setHome = useControls((state) => state.setHome)
  const displayMenus = useControls((state) => state.displayMenus)

  //home detection
  if (window.location.pathname === "/") {
    setHome(true)
  }
  else {
    displayMenus(true)
  }

  useEffect(() => {
    async function storeContent() {
        const contactContent = await getPagesContent(0)
        const aboutContent = await getPagesContent(1)
        if (contactContent) setContactContent(contactContent)
        if (aboutContent) setAboutContent(aboutContent)
    }
    storeContent();
  }, [])

  return (
    <div className="App">
      <GameSummary/>
      <ScoreCounter/>
      <LevelLabel/>
      <GameInstructions/>
      <RadioPlayer/>
      <Scene/>
      <KeyboardEvents/>
      <Nav className="navMenu"/>
      <Preloader/>
      <GraphicsControls/>
      <GameTutorial className="gameTutorial"/>
      <GameEndingCountdown/>
      <MobileSoundControl className="mobileSoundControl"/>
      <MobileControls className="mobileControls"/>  
    </div>
  );
}

export default App;
