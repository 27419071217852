import create from 'zustand'

const usePopup = create((set) => ({
  bloomPopup: false,
  openBloomPopup: () => set(() => ({
    bloomPopup: true
  })),
  closeBloomPopup: () => set(() => ({
    bloomPopup: false
  }))  
}))

export default usePopup