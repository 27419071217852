import create from 'zustand'
import { constants } from '../constants'
const useGame = create((set) => ({
  game: false,
  levelIndex: 0,
  carFrontPosition: constants.CAR_BREAKING_POSITION,
  carSidePosition: constants.CAR_SIDE_POSITION,
  gameIntroductionDuration: constants.GAME_INTRODUCTION_DURATION,
  instructionsStep: 0,
  instructions: false,
  displayLevel: false,
  score: 0,
  finalScore: 0,
  endingCountdown: false,
  endingMessage: false,
  setCarFrontPosition: (position) => set(() => ({
    carFrontPosition: position
  })),
  setCarSidePosition: (position) => set(() => ({
    carSidePosition: position
  })), 
  startPlaying: () => set(() => ({
    game: true
  })),
  endGame: () => set(() => ({
    game: false
  })),
  setLevelIndex: (value) => set(() => ({
    levelIndex: value
  })),
  setInstructionsStep: (value) => set(() => ({
    instructionsStep: value
  })),
  setInstructions: (value) => set(() => ({
    instructions: value
  })),
  setLevelDisplay: (value) => set(() => ({
    displayLevel: value
  })),
  setScore: (value) => set(() => ({
    score: value
  })),
  displayEndingCountdown: (value) => set(() => ({
    endingCountdown: value
  })),
  displayEndingMessage: (value) => set(() => ({
    endingMessage: value
  })),
  setFinalScore: (value) => set(() => ({
    finalScore: value
  }))
}))

export default useGame