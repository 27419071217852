import styles from "./GameSummary.module.scss"
import { useControls, useGame } from "../../store"
import { constants } from "../../constants"
//import { words } from "./randomWords"
import gsap from "gsap"
import { useEffect, useRef } from "react"

//Random name generation for later use
/* function getRandomInt(min, max) {
    return Math.round(Math.random() * (max - min) + min);
}

function randomNameGenerator() {
    return `${words[getRandomInt(0, 250)]}${words[getRandomInt(0, 250)]}${getRandomInt(0, 250)}`
}
 */

function GameSummary() {
    //stored functions
    const displayMenus = useControls((state) => state.displayMenus)
    const displayEndingMessage = useGame((state) => state.displayEndingMessage)
    const setScore = useGame((state) => state.setScore)
    //stored values
    const score = useGame((state) => state.score)
    const endingMessage = useGame((state) => state.endingMessage)
    //refs
    const gameSummary = useRef()

    const close = () => {
        displayMenus(true)
        displayEndingMessage(false)
        setScore(0)
    }

    useEffect(() => {
        if (endingMessage) {
            gsap.to(gameSummary.current, { opacity: 1, duration: 0.5 })
        }
        else {
            gsap.to(gameSummary.current, { opacity: 0, duration: 0 })
        }
    })
    return (
        <div ref={gameSummary} className="gameSummary">
            <div className={styles.container}>
                <div className={styles.title}>{constants.FINAL_SCORE}</div><br />
                <div className={styles.score}>{score}</div><br /><br />
                <button onClick={close}>{constants.BACK_TO_SITE}</button>
            </div>
        </div>
    )
}

export default GameSummary