import styles from "./Popup.module.scss"
import { constants } from "../../constants"
function Popup(props){
    const openPopup = props.openPopup
    const validate = () =>{props.validate()}
    const decline = () =>{props.decline()}
    return openPopup ? (
        <div className={styles.overlay}>
            <div className={styles.container}>
                <div>
                    <p className={styles.message}>{props.message}</p>
                    <br/>
                    <div className={styles.buttonsContainer}>
                        <button className={styles.button} onClick={validate}>{constants.YES}</button>
                        <button className={styles.button} onClick={decline}>{constants.NO}</button>
                    </div>
                </div>
            </div>
        </div>
    ) : null 
}

export default Popup