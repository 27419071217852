import { useRef, useEffect } from 'react'
import { useFrame } from "@react-three/fiber"
import { useGLTF } from '@react-three/drei'
import { useControls, useCamera, useGame } from '../../../store'
import helicopter from './helicopter.glb'
import { constants } from '../../../constants'
import gsap from 'gsap'

function Helicopter({ ...props }) {
  //refs
  const group = useRef()
  const pale1 = useRef()
  const pale2 = useRef()
  const backPale = useRef()

  //import gltf
  const nodes = useGLTF(helicopter)?.nodes

  //stored values
  const key = useControls((state) => state.keyPressed)
  const introCompleted = useCamera((state) => state.introCompleted)
  const game = useGame((state) => state.game)
  const levelIndex = useGame((state) => state.levelIndex)
  const siteStarted = useControls((state) => state.siteStarted)

  useFrame(()=>{
    pale1.current.rotation.y += 0.5
    pale2.current.rotation.y += 0.5
    backPale.current.rotation.x += 0.5
  })

  useEffect(() => {
        if(siteStarted){
          gsap.to(group.current.position, {z: -25, duration: 20})
        }
        if(introCompleted){
          if (key === constants.RIGHT){
              gsap.to(group.current.position, {x: -12, duration: constants.HELICOPTER_MOVEMENT})
              gsap.timeline()
              .to(group.current.rotation, {z: -0.3, duration: constants.HELICOPTER_MOVEMENT})
              .to(group.current.rotation, {z: 0, duration: constants.HELICOPTER_MOVEMENT})
          }
          if (key === constants.LEFT){
              gsap.to(group.current.position, {x: -18, duration: constants.HELICOPTER_MOVEMENT})
              gsap.timeline()
              .to(group.current.rotation, {z: 0.3, duration: constants.HELICOPTER_MOVEMENT})
              .to(group.current.rotation, {z: 0, duration: constants.HELICOPTER_MOVEMENT})
          }
          if (key === constants.UP){
            gsap.to(group.current.rotation, {x: -Math.PI/4, duration: constants.HELICOPTER_MOVEMENT})
          }
          if (key === constants.DOWN){
            gsap.to(group.current.rotation, {x: 0, duration: constants.HELICOPTER_MOVEMENT})
          }
        }
  })

  return (
    <group ref={group} {...props} dispose={null}>
        <mesh 
          geometry={nodes.Copter.geometry} 
          material={nodes.Copter.material} 
          position={[1.27, -6.5, 0.5]} 
        >
          <meshStandardMaterial color={game ? constants.HELICOPTER_GAME_COLOR[levelIndex] : constants.HELICOPTER_COLOR} metalness={game ? 0 : 0.7} roughness={0.5}/>
        </mesh>
        <mesh 
          ref={pale1}
          geometry={nodes.Pale1.geometry} 
          material={nodes.Pale1.material} 
          position={[0, 1.61, 0.59]} 
        >
          <meshStandardMaterial color={game ? constants.HELICOPTER_GAME_COLOR[levelIndex] : constants.HELICOPTER_COLORR}/>
        </mesh>
        <mesh
          ref={pale2}
          geometry={nodes.Pale2.geometry}
          material={nodes.Pale2.material}
          position={[0, 1.66, 0.59]}
          rotation={[0, -0.44, 0]}
        >
          <meshStandardMaterial color={game ? constants.HELICOPTER_GAME_COLOR[levelIndex] : constants.HELICOPTER_COLORR}/>
        </mesh>
        <mesh
          ref={backPale}
          geometry={nodes.BackPale.geometry}
          material={nodes.BackPale.material}
          position={[0, 1.43, 3.96]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[0.33, 0.15, 0.05]}
        >
          <meshStandardMaterial color={game ? constants.HELICOPTER_GAME_COLOR[levelIndex] : constants.HELICOPTER_COLORR}/>
        </mesh>
        <mesh
          geometry={nodes.Light.geometry}
          material={nodes.Light.material}
          position={[0, -1.05, -0.09]}
          rotation={[Math.PI / 4, 0, 0]}
          scale={0.19}
        >
          <meshBasicMaterial color={game ? constants.HELICOPTER_GAME_COLOR[levelIndex] : constants.HELICOPTER_COLORR}/>
        </mesh>
    </group>
  )
}

useGLTF.preload(helicopter)

export default Helicopter