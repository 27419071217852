import create from 'zustand'

const useContent = create((set) => ({
  aboutContent: null,
  contactContent: null,
  setAboutContent: (value) => set(() => ({
    aboutContent: value
  })),
  setContactContent: (value) => set(() => ({
    contactContent: value
  }))
}))

export default useContent