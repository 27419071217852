import create from 'zustand'
import useCamera from './camera'
import useGame from './game'
import { constants } from '../constants'

const useControls = create((set) => ({
  carFrontSlideSpeed: 0.5,
  carBreakingSlideSpeed: 0.3,
  carLateralSlideSpeed: 1,
  carWheelsSpeed: 0.05,
  keyPressed: "",
  speed: constants.INITSPEED,
  breaksColor: constants.CAR_INACTIVE_BREAKS_COLOR,
  backBreaksIntensity: 0,
  radio: false,
  multiplicator: 1,
  isHighSpeed: false,
  isLowSpeed: true,
  siteStarted: false,
  navigation: false,
  menus: false,
  home: false,
  setKey: (key) => set(() => ({ 
    keyPressed: useCamera.getState().introCompleted ? key : ""
  })),
  setHighSpeed: () => set((state) => ({ 
    speed: !state.isHighSpeed && useCamera.getState().introCompleted ? state.speed * 2 : state.speed,
    isHighSpeed: true,
    isLowSpeed: false
  })),
  setLowSpeed: () => set((state) => ({ 
    speed: !state.isLowSpeed && useCamera.getState().introCompleted ? state.speed / 2 : state.speed,
    isLowSpeed: true,
    isHighSpeed: false
  })),
  setFrontSlideSpeed: (value) => set(() => ({ 
    carFrontSlideSpeed: value
  })),
  setLateralSlideSpeed: (value) => set(() => ({ 
    carLateralSlideSpeed: value
  })),
  setBreaksLight: (value) => set(() => ({ 
    breaksColor: value ? useGame.getState().game ? constants.CAR_GAME_BREAKS_COLOR[useGame.getState().levelIndex] : constants.CAR_BREAKS_COLOR : constants.CAR_INACTIVE_BREAKS_COLOR,
    backBreaksIntensity: value ? 1 : 0
  })),
  setCarMoving: (value) => set(() => ({ 
    moving: value
  })),
  setRadio: () => set((state) => ({ 
    radio: !state.radio
  })),
  setSpeed: (value) => set(() => ({ 
    speed: value
  })),
  startSite: () => set(() => ({ 
    siteStarted: true
  })),
  setNavigation: (value) => set(() => ({ 
    navigation: value
  })),
  displayMenus: (value) => set(() => ({ 
    menus: value
  })),
  setHome: (value) => set(() => ({ 
    home: value
  }))
}))

export default useControls