function Terrain(props){
    return (
        <group group {...props} dispose={null}>
            <mesh rotation-x={-Math.PI/2}>
                <planeGeometry args={[40, 150]} />
                <meshPhysicalMaterial color="green"/>
            </mesh>
        </group>
    )
}
export default Terrain