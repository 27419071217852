import { Canvas, useThree, useFrame } from "@react-three/fiber"
import { OrbitControls, AdaptiveDpr, Stars} from "@react-three/drei"
import { Suspense, useLayoutEffect, useEffect } from "react"
import {EffectComposer, Bloom} from "@react-three/postprocessing"
import Trees from "./trees/Trees"
import Beach from "./beach/Beach"
import Car from "./car/Car"
import Ocean from "./ocean/Ocean"
import Helicopter from "./helicopter/Helicopter"
import Road from "./road/Road"
import Town from "./town/Town"
import Mountains from "./mountains/Mountains"
import Sun from "./sun/Sun"
import CarLights from "./carLights/CarLights"
import Camera from "./camera/Camera"
import Game from "./game"
import { constants } from "../../constants"
import { useCamera, useGame } from "../../store"

function FPSLimiter() {
    const set = useThree((state) => state.set);
    const get = useThree((state) => state.get);
    const advance = useThree((state) => state.advance);
    const frameloop = useThree((state) => state.frameloop);
    
    useLayoutEffect(() => {
        const initFrameloop = get().frameloop;
        return () => {
            set({ frameloop: initFrameloop });
        };
    }, []);

    useFrame((state) => {
        if (state.get().blocked) return;
        state.set({ blocked: true });
        setTimeout(() => {
            state.set({ blocked: false });
            state.advance();
        }, Math.max(0, 1000 / constants.FPS_CAP - state.clock.getDelta()));
    });

    useEffect(() => {
        if (frameloop !== 'never') {
            set({ frameloop: 'never' });
            advance();
        }
    }, [frameloop]);

    return null;
}

function Scene(){
    //stored values
    const dpr = useCamera((state) => state.dpr)
    const bloom = useCamera((state) => state.bloom)
    const game = useGame((state) => state.game)
    const levelIndex = useGame((state) => state.levelIndex)
    const introCompleted = useCamera((state)=>state.introCompleted)

    return (
        <div style={{ width: "100vw", height: "100vh", position: "fixed", top: 0, left: 0 }}>
            <Canvas
                style={{background: game ? constants.SKY_GAME_GRADIENT[levelIndex] : constants.SKY_GRADIENT}}
                className="sceneCanvas"
                dpr={dpr}
            >   
                {introCompleted ? (
                    <FPSLimiter/>
                ):null}
                <fog attach="fog" args={[constants.FOG_COLOR, 1, 50]} />
                <CarLights position={constants.CAR_INIT_POSITIONS}/>
                <AdaptiveDpr pixelated/>
                {
                    bloom ? (
                        <EffectComposer> 
                            <Bloom luminanceThreshold={0} luminanceSmoothing={0.9} height={150} />
                        </EffectComposer>
                    ) : null
                }
                <Camera/>
                <OrbitControls/>
                <pointLight position={[10, 10, 100]} color={constants.POINTLIGHT_COLOR} intensity={0.5}/>
                <Suspense fallback={null}>
                    <Game/>
                    <Stars radius={100} depth={50} count={1000} factor={4} saturation={0} fade speed={1}/>
                    <Beach position={[-2.6, 0.5, -4]}/>
                    <Car position={constants.CAR_INIT_POSITIONS}/>
                    <Helicopter position={constants.HELICOPTER_INIT_POSITIONS}/>
                    <Ocean position={[0, 0, -50]}/>
                    <Road position={[1, 1, 0]}/>
                    <Trees position-x={4.5}/>
                    <Town position={[23.5, 1, 0]} />
                    <Mountains rotation-y={-Math.PI/2} position={[-26, -0.4, -65]} scale={20}/>
                    <Mountains scale={[10,10,30]} position-x={73}/>
                    <Sun position={[-30, 0, -69]}/>
                </Suspense>
            </Canvas>
        </div>
    )
}

export default Scene