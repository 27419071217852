import { useRef, useEffect } from "react"
import styles from "./GameTutorial.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faArrowLeft, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { constants } from "../../constants"
import { useControls } from "../../store"
import gsap from "gsap";

function GameTutorial(props){
    //stored values
    const menus = useControls((state) => state.menus)
    
    //refs
    const gameTutorial = useRef()
    useEffect(()=>{
        if(menus) gsap.to(gameTutorial.current, {right: 0, duration: 0.5})
        if(!menus) gsap.to(gameTutorial.current, {right: -400, duration: 0.5})
    })
    return(
        <div ref={gameTutorial} className={props.className}>
            <h3 className={styles.title}>{constants.CONTROLS}</h3>
            <ul className={styles.list}>
                <li className={styles.listItem}>
                    <span className={styles.column}><FontAwesomeIcon icon={faArrowRight} className={styles.icon}/></span>
                    <span className={styles.column}>{constants.TURN_RIGHT}</span>
                </li>
                <li className={styles.listItem}>
                    <span className={styles.column}><FontAwesomeIcon icon={faArrowLeft} className={styles.icon}/></span>
                    <span className={styles.column}>{constants.TURN_LEFT}</span>
                </li>
                <li className={styles.listItem}>
                    <span className={styles.column}><FontAwesomeIcon icon={faArrowUp} className={styles.icon}/></span>
                    <span className={styles.column}>{constants.ACCELERATE}</span>
                </li>
                <li className={styles.listItem}>
                    <span className={styles.column}><FontAwesomeIcon icon={faArrowDown} className={styles.icon}/></span>
                    <span className={styles.column}>{constants.BREAK}</span>
                </li>
                <li className={styles.listItem}>
                    <span className={styles.column}><span className={styles.icon}>{constants.RADIO_SYMBOL}</span></span>
                    <span className={styles.column}>{constants.RADIO}</span>
                </li>
            </ul>
        </div>
    )
}

export default GameTutorial