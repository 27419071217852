export const constants = {
    //colors
    CAR_MAIN_COLOR: "red",
    CAR_SECONDARY_COLOR: "black",
    CAR_INACTIVE_BREAKS_COLOR: "black",
    CAR_BREAKS_COLOR: "red",
    CAR_BREAKLIGHTS_COLOR: "red",
    CAR_FRONTLIGHTS_COLOR: "yellow",
    WHEEL_COLOR: "#755c1b",
    HELICOPTER_COLOR: "white",
    ROADLINES_COLOR: "yellow",
    TREES_TRUNK_COLOR: "brown",
    TREES_FOLIAGE_COLOR: "green",
    BEACH_COLOR: "#de9ef7",
    WATER_COLOR: "blue",
    SUN_COLOR_1: "violet",
    SUN_COLOR_2: "#2d014a",
    SKY_GRADIENT: "linear-gradient(-45deg, #0e2225, #050616, #05051e, #01362a)",
    DIAMOND_COLOR: "#ffffff",
    WATER_BACKGROUND_COLOR: "black",
    FOG_COLOR: "black",
    POINTLIGHT_COLOR: "white",
    //& game colors
    BEACH_GAME_COLOR: ["#ffb3fd", "#f50021", "#00ff6e", "violet", "yellow", "white"],
    TREES_GAME_FOLIAGE_COLOR: ["#17fbff", "red", "#00ff6e", "violet", "red", "white"],
    TREES_GAME_TRUNK_COLOR: ["white", "red",  "white", "rose", "yellow", "white"],
    ROADLINES_GAME_COLOR: ["#03f7ff", "red", "white", "violet", "yellow", "white"],
    WHEEL_GAME_COLOR: ["white", "#f50021", "white", "black", "yellow", "white"],
    CAR_GAME_BREAKS_COLOR: ["#eb0cab", "#f50021", "#00ff6e", "violet", "red", "white"],
    CAR_GAME_BREAKLIGHTS_COLOR: ["#eb0cab", "#f50021", "#00ff6e", "violet", "red", "white"],
    CAR_GAME_FRONTLIGHTS_COLOR: ["#ffffff", "red", "#00ff6e", "#ff00f2", "yellow", "white"],
    WATER_GAME_COLOR: ["white", "red", "green", "violet", "yellow", "white"],
    CAR_GAME_MAIN_COLOR: ["violet", "#f02461", "#00ff6e", "#ff00f2", "red", "white"],
    SUN_GAME_MAIN_COLOR_1: ["#12fff3", "#ff6200", "#00ff6e", "#ff00f2", "red", "#19ffff"],
    SUN_GAME_MAIN_COLOR_2: ["#e017ff", "#8a0f2a", "white", "#ff00f2", "yellow", "black"],
    CAR_RIM_COLOR: "#ffffff",
    BUILDINGS_GAME_COLOR: ["white", "red", "white", "violet", "red", "white"],
    DIAMOND_GAME_COLOR: ["#ffffff", "#f5021f", "white", "#ff00f2", "yellow", "white"],
    HELICOPTER_GAME_COLOR: ["#12fff3", "red", "white", "#ff00f2", "yellow","white"],
    SKY_GAME_GRADIENT: ["linear-gradient(-45deg, #0e2525, #05160d, #050a1e, #005a61)", "linear-gradient(-45deg, #250e0e, #160505, #1e0505, #3e0000)", "linear-gradient(-45deg, #ffffff, #0ada37, #003107, #022500)", "linear-gradient(-45deg, #c905a8, #350035, #e008d2, #28003a)", "linear-gradient(-45deg, #c90505, #dac50a, #e00808, #230606)", "linear-gradient(-45deg, #ffffff, #befffd, #9ffff5, #007a72)"],
    //game
    CONTROLS: "Controls",
    TURN_RIGHT: "Turn right",
    TURN_LEFT: "Turn left",
    ACCELERATE: "Accelerate",
    BREAK: "Break",
    TRIGGER_EVENT: "keydown",
    GAME_INTRODUCTION_DURATION: 5,
    CAR_SIDE_POSITION: "left",
    RADIO: "Radio ON/OFF",
    RADIO_SYMBOL: "R",
    FPS_CAP: 60,
    //gameLabels
    LEVELS_LABEL: "There are six levels.", 
    SPEED_LABEL: "Speed increases at each level.", 
    DIAMOND_LABEL: "Get all the diamonds you can get.",
    POINTS_LABEL: "Playing at high speed gives you more points.",
    READY_LABEL: "Ready ?",
    GO_LABEL: "Go !",
    LEVELS_LABELS: [
        "Level 1 : Phocean Blue",
        "Level 2 : Red Devil",
        "Level 3 : Green Disillusionment",
        "Level 4 : Tuscany Spirit",
        "Level 5 : Coil taste",
        "Level 6 : La Casa Blanca"
    ],
    FINAL_SCORE: "Final score",
    CONGRATULATIONS: "Congratulations !",
    BACK_TO_SITE: "Back to site",
    NAME_GIVEN: "You've been given the name :",
    ADDED_TO_HIGHSCORE: "It will be added to highscores.",
    TOUCH_ZONE_LABEL: "Touch the corresponding screen zone to control the car.",
    //menu
    PLAY: "Play",
    ABOUT: "About",
    CONTACT: "Contact",
    //controls
    INITSPEED: 0.4,
    RIGHT: "right",
    LEFT: "left",
    UP: "up",
    DOWN: "down",
    START_SITE: "Begin",
    //car
    CAR_INIT_POSITIONS: [0, 1.30, 5],
    CAR_RIGHT_POSITION: 2,
    CAR_LEFT_POSITION: 0,
    CAR_BREAKING_POSITION: 0,
    CAR_ACCELERATING_POSITION: -1,
    //Helicopter
    HELICOPTER_INIT_POSITIONS: [-15, 5, 50],
    HELICOPTER_MOVEMENT: 1.12,
    //Sound
    RADIO_URL: "https://stream.nightride.fm/nightride.mp3",
    PLAYSOUND: "Play",
    PAUSESOUND: "Pause",
    //buildings
    BUILDING_BASIC_HEIGHT: 4,
    //popup
    YES: "yes",
    NO: "no",
    MESSAGE: "Bloom can be very consuming. Are you sure ?",
    //labels
    WELCOME_TITLE: "Welcome",
    WELCOME_SUBTITLE_UP: "Vincent Lhoste",
    WELCOME_SUBTITLE_DOWN: "portfolio",
    CONTACT_TITLE: "Contact",
    ABOUT_TITLE: "About",
    //api's
    CONTENT_URL: "https://vincentlhoste.fr/vincentlhoste",
    //socials
    LINKEDIN: "https://www.linkedin.com/in/vincent-lhoste-4a220792/",
    INSTAGRAM: "https://www.instagram.com/jacklhoste/",
    LINKEDIN_ALT: "Logo linkedin",
    INSTAGRAM_ALT: "Logo Instagram"
}