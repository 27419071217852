import { useGLTF } from "@react-three/drei"
import tree from "./tree.glb"
import { useGame } from "../../../../store"
import { constants } from "../../../../constants"

function Tree(props) {
  //stored values
  const game = useGame((state) => state.game)
  const levelIndex = useGame((state) => state.levelIndex)
  //loading gltf
  const { nodes } = useGLTF(tree)
  
  return (
    <group {...props} dispose={null}>
        <mesh geometry={nodes.Trunk.geometry} position={[0, 3.21, 0.65]} rotation={[-Math.PI / 2, 0, 0]} scale={[1, 1, 3.41]}>
          <meshBasicMaterial color={game ? constants.TREES_GAME_FOLIAGE_COLOR[levelIndex] : constants.TREES_TRUNK_COLOR}/>
        </mesh>
        <mesh geometry={nodes.Foliage.geometry} position={[0, 3.21, 0.65]} rotation={[-Math.PI / 2, 0, 0]} scale={[1, 1, 3.41]}>
          <meshStandardMaterial color={game ? constants.TREES_GAME_FOLIAGE_COLOR[levelIndex] : constants.TREES_FOLIAGE_COLOR}/>
        </mesh>
    </group>
  )
}

useGLTF.preload(tree)

export default Tree