import styles from "./Contact.module.scss"
import BackToHomeButton from "../../components/backToHomeButton/BackToHomeButton"
import { useContent } from "../../store";
import { constants } from "../../constants";

function Contact(){
    const contactContent = useContent((state) => state.contactContent);
    
    return (
        <div className="pageContainer">
            <div className={styles.container}>
                <div className="pageHeader">
                    <h1 className="pageTitle">{constants.CONTACT_TITLE}</h1>
                    <BackToHomeButton/>
                </div>
                <div
                    className="pageContent"
                    dangerouslySetInnerHTML={{__html: contactContent}}
                />
            </div>
        </div>
    )
}
export default Contact