import {  useGLTF } from "@react-three/drei"
import { useRef, useEffect } from "react"
import { useFrame } from "@react-three/fiber"
import {useControls, useLights, useGame, useCamera} from "../../../store"
import car from "./car.glb"
import { gsap } from "gsap";
import {DoubleSide} from "three"
import { constants } from "../../../constants"

function Car(props) {
  const WHEEL_TURNING_DURATION = 0.5

  //stored functions
  const setBreaksLight = useControls((state) => state.setBreaksLight)
  const setKey = useControls((state) => state.setKey)
  const setCarFrontLightsPositionZ = useLights((state) => state.setCarFrontLightsPositionZ)
  const setCarBackLightsPositionZ = useLights((state) => state.setCarBackLightsPositionZ)
  const setCarSidePosition = useGame((state) => state.setCarSidePosition)

  //init
  setCarFrontLightsPositionZ(props.position[2]-2.5)
  setCarBackLightsPositionZ(props.position[2]+1.2)

  //stored values
  const key = useControls((state) => state.keyPressed)
  const breaksColor = useControls((state) => state.breaksColor)
  const carLateralSlideSpeed = useControls((state) => state.carLateralSlideSpeed)
  const carFrontSlideSpeed = useControls((state) => state.carFrontSlideSpeed)
  const carBreakingSlideSpeed = useControls((state) => state.carBreakingSlideSpeed)
  const carWheelsSpeed = useControls((state) => state.carWheelsSpeed)
  const introCompleted = useCamera((state) => state.introCompleted)
  const game = useGame((state) => state.game)
  const levelIndex = useGame((state) => state.levelIndex)
  
  //load car
  const { nodes, materials } = useGLTF(car)
  //refs
  const carO = useRef()
  const carBody = useRef()
  const rightFrontwheel = useRef()
  const leftFrontWheel = useRef()
  const rightBackWheel = useRef()
  const leftBackWheel = useRef()
  const frontWheels = useRef()
  const backWheels = useRef()
  const steeringWheel = useRef()
  const backBreaks = useRef()
  const mainCarMaterial = useRef()
  const wheelMaterial = useRef()
  
  function turnWheels(angle = 0.1){
    animateWheels(angle, backWheels)
    animateWheels(angle, frontWheels)
  }

  function animateWheels(angle, wheels){
    gsap.timeline()
    .to(wheels.current.rotation, {y: angle, duration: 0.5})
    .to(wheels.current.rotation, {y: 0, duration: 0.5})
  }
  
  useEffect(() => {
    if(introCompleted){
      if (key === constants.RIGHT){
          gsap.to(carO.current.position, {x: constants.CAR_RIGHT_POSITION, duration: carLateralSlideSpeed})
          gsap.timeline()
          .to(steeringWheel.current.rotation, {y: steeringWheel.current.rotation.y+1, duration: WHEEL_TURNING_DURATION})
          .to(steeringWheel.current.rotation, {y: Math.PI/2, duration: WHEEL_TURNING_DURATION})
          gsap.timeline()
          .to(carO.current.rotation, {y: carO.current.rotation.y - 0.1, duration: WHEEL_TURNING_DURATION})
          .to(carO.current.rotation , {y: -Math.PI, duration: WHEEL_TURNING_DURATION})
          gsap.timeline()
          .to(carBody.current.rotation, {z: carBody.current.rotation.z + 0.005, duration: WHEEL_TURNING_DURATION})
          .to(carBody.current.rotation , {z: 0, duration: WHEEL_TURNING_DURATION})
          setCarSidePosition(constants.RIGHT)
          turnWheels(-0.05)
      }
      if (key === constants.LEFT){
          gsap.to(carO.current.position, {x: constants.CAR_LEFT_POSITION, duration: carLateralSlideSpeed})
          gsap.timeline()
          .to(steeringWheel.current.rotation, {y: steeringWheel.current.rotation.y-1, duration: WHEEL_TURNING_DURATION})
          .to(steeringWheel.current.rotation, {y: Math.PI/2, duration: WHEEL_TURNING_DURATION})
          gsap.timeline()
          .to(carO.current.rotation , {y: carO.current.rotation.y + 0.1, duration: WHEEL_TURNING_DURATION})
          .to(carO.current.rotation , {y: -Math.PI, duration: WHEEL_TURNING_DURATION})
          gsap.timeline()
          .to(carBody.current.rotation, {z: carBody.current.rotation.z - 0.005, duration: WHEEL_TURNING_DURATION})
          .to(carBody.current.rotation , {z: 0, duration: WHEEL_TURNING_DURATION})
          setCarSidePosition(constants.LEFT)
          turnWheels(0.05)
      }
      if (key === constants.UP){
        gsap.to(carO.current.position, {z: constants.CAR_ACCELERATING_POSITION, duration: carFrontSlideSpeed})
        setKey("")
      }
      if (key === constants.DOWN){
        setBreaksLight(true)
        if (carO.current.position.z === -1){
          gsap.to(carO.current.position, {z: constants.CAR_BREAKING_POSITION, duration: carBreakingSlideSpeed})
          setKey("")
        }
        else {
          gsap.timeline()
            .to(carO.current.position, {z: 0.5, duration: carBreakingSlideSpeed})
            .to(carO.current.position, {z: constants.CAR_BREAKING_POSITION, duration: carBreakingSlideSpeed})
          setKey("")
        }
        setTimeout(()=>{
          setBreaksLight(false)
        }, 1000)
      }
    }
  }, [key])

  //permanent animations
  useFrame(() => {
    rightFrontwheel.current.rotation.x += carWheelsSpeed
    leftFrontWheel.current.rotation.x += carWheelsSpeed
    rightBackWheel.current.rotation.x += carWheelsSpeed
    leftBackWheel.current.rotation.x += carWheelsSpeed
  })

  return (
    <group {...props} dispose={null}>
      <group rotation-y={-Math.PI} scale={0.2} ref={carO}>
          <group scale={[1, 0.85, 5.95]} ref={carBody}>
            <mesh geometry={nodes.Cube001.geometry}>
              <meshStandardMaterial ref={mainCarMaterial} color={game ? constants.CAR_GAME_MAIN_COLOR[levelIndex] : constants.CAR_MAIN_COLOR} metalness={0.5} roughness={0.8} side={DoubleSide}/>
            </mesh>
            <mesh geometry={nodes.Cube001_1.geometry}>
              <meshStandardMaterial color={constants.CAR_SECONDARY_COLOR} side={DoubleSide}/>
            </mesh>
            <mesh geometry={nodes.Cube001_2.geometry}>
              <meshStandardMaterial color={constants.CAR_SECONDARY_COLOR} transparent={true} opacity={0.5} side={DoubleSide}/>
            </mesh>
            <mesh geometry={nodes.Cube001_3.geometry}>
              <meshStandardMaterial color={constants.CAR_SECONDARY_COLOR} side={DoubleSide}/>
            </mesh>
            <mesh geometry={nodes.Cube001_4.geometry}>
              <meshStandardMaterial color={constants.CAR_SECONDARY_COLOR} side={DoubleSide}/>
            </mesh>
            <mesh geometry={nodes.Cube001_5.geometry}>
              <meshStandardMaterial color={constants.CAR_MAIN_COLOR} side={DoubleSide}/>
          </mesh>
          </group>
          <group ref={frontWheels}>
            <group position={[-2.23, -0.63, 3.82]} ref={rightFrontwheel}>
                <mesh geometry={nodes.Cube002.geometry} material={materials["Material.001"]}>
                  <meshStandardMaterial ref={mainCarMaterial} color={game ? constants.CAR_GAME_MAIN_COLOR[levelIndex] : constants.CAR_RIM_COLOR} metalness={0.5} roughness={0.8} side={DoubleSide}/>
                </mesh>
                <mesh geometry={nodes.Cube002_1.geometry} material={materials["Material.002"]} />
                <mesh geometry={nodes.Cube002_2.geometry} material={materials["Material.007"]} />
            </group>
            <group position={[2.23, -0.63, 3.82]} ref={leftFrontWheel}>
              <mesh geometry={nodes.Cube008.geometry} material={materials["Material.001"]}>
                <meshStandardMaterial ref={mainCarMaterial} color={game ? constants.CAR_GAME_MAIN_COLOR[levelIndex] : constants.CAR_RIM_COLOR} metalness={0.5} roughness={0.8} side={DoubleSide}/>
              </mesh>
              <mesh geometry={nodes.Cube008_1.geometry} material={materials["Material.002"]} />
              <mesh geometry={nodes.Cube008_2.geometry} material={materials["Material.007"]} />
            </group>
          </group>
          <group ref={backWheels}>
            <group position={[-2.23, -0.53, -3.08]} ref={rightBackWheel}>
              <mesh geometry={nodes.Cube009.geometry} material={materials["Material.001"]}>
                <meshStandardMaterial ref={mainCarMaterial} color={game ? constants.CAR_GAME_MAIN_COLOR[levelIndex] : constants.CAR_RIM_COLOR} metalness={0.5} roughness={0.8} side={DoubleSide}/>
              </mesh>
              <mesh geometry={nodes.Cube009_1.geometry} material={materials["Material.002"]} />
              <mesh geometry={nodes.Cube009_2.geometry} material={materials["Material.007"]} />
            </group>
            <group position={[2.23, -0.53, -3.08]} ref={leftBackWheel}>
              <mesh geometry={nodes.Cube007.geometry} material={materials["Material.001"]}>
                <meshStandardMaterial ref={mainCarMaterial} color={game ? constants.CAR_GAME_MAIN_COLOR[levelIndex] : constants.CAR_RIM_COLOR} metalness={0.5} roughness={0.8} side={DoubleSide}/>
              </mesh>
              <mesh geometry={nodes.Cube007_1.geometry} material={materials["Material.002"]} />
              <mesh geometry={nodes.Cube007_2.geometry} material={materials["Material.007"]} />
            </group>
          </group>
          <group>
            <mesh
              ref={steeringWheel}
              geometry={nodes.Steering.geometry}
              position={[1.38, 0.73, 1.07]}
              rotation={[Math.PI / 2, 1.57, 0]}
              scale={[0.13, -0.07, 0.26]}
            >
              <meshStandardMaterial ref={wheelMaterial} color={game ? constants.WHEEL_GAME_COLOR[levelIndex] : constants.WHEEL_COLOR}/>
            </mesh>
          </group>
          <group>
            <mesh
              geometry={nodes.backLights.geometry}
              position={[0, 0, 1.14]}
              scale={[1, 0.85, 5.95]}
            >
              <meshBasicMaterial ref={backBreaks} color={breaksColor}/>
            </mesh>
          </group>
          <group scale={[1, 0.85, 5.95]}>
            <mesh geometry={nodes.Cube005.geometry} material={nodes.Cube005.material} />
            <mesh geometry={nodes.Cube005_1.geometry} material={materials['Material.005']} />
          </group>
      </group>
    </group>
  )
}

useGLTF.preload(car)

export default Car