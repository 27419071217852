import styles from "./Preloader.module.scss"
import {useEffect, useRef} from "react"
import gsap from "gsap";
import { useControls } from "../../store"
import { constants } from "../../constants"

function Preloader(){
    //stored functions
    const startSite = useControls((state) => state.startSite)
    //stored values
    const siteStarted = useControls((state) => state.siteStarted)
    const home = useControls((state) => state.home)

    //refs
    const topLetterBoxing = useRef()
    const bottomLetterBoxing = useRef()
    const titleContainer = useRef()
    const subtitleContainer = useRef()
    const startSiteButton = useRef()
    const container = useRef()

    function animateLoader(loader, side){
        gsap.timeline()
            .to(loader, {height: "15%", top: side === "top" ? "0%" : "70%", delay: 0.5, duration: 3})
            .to(loader, {height: "0%", top: side === "top" ? "0%" : "100%", delay: 16.5, duration: 0.5})
    }

    const start = () => {
        startSite()
    }

    useEffect(()=>{
        if(siteStarted){
            gsap.to(startSiteButton.current, {width: 100, opacity:0, delay: 0, duration: 0.5})
            animateLoader(bottomLetterBoxing.current, "bottom")
            animateLoader(topLetterBoxing.current, "top")
            gsap.to(titleContainer.current, {opacity:0, delay: 9, duration: 0.5})
            gsap.to(container.current, {pointerEvents:"none", delay: 19.5, duration: 0.5})

            gsap.timeline()
            .to(subtitleContainer.current, {opacity: 1, delay: 11, duration: 0.5})
            .to(subtitleContainer.current, {opacity: 0, delay: 8, duration: 0.5})

        }
    })

    return home ? (
            <div ref={container} className={styles.container}>
                <div ref={topLetterBoxing} className={styles.topLetterBoxing}/>
                <div ref={titleContainer} className={styles.titleContainer}>
                    <div className={styles.title}>{constants.WELCOME_TITLE}</div>
                </div>
                <button ref={startSiteButton} className={styles.test} onClick={start}>start</button>
                <div ref={subtitleContainer} className={styles.subtitleContainer}>
                    <div className={styles.subtitle}>
                        {constants.WELCOME_SUBTITLE_UP}
                        <br/>
                        -
                        <br/>
                        <span className={styles.bolded}>{constants.WELCOME_SUBTITLE_DOWN}</span>
                    </div>
                </div>
                <div ref={bottomLetterBoxing} className={styles.bottomLetterBoxing}/>
            </div>
    ) : null
}

export default Preloader