export default async function getPagesContent(page){
    let content = null
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    await fetch("https://vincentlhoste.fr/vincentlhoste/wp-json/wp/v2/pages", requestOptions)
        .then(response => response.text())
        .then(result => content = JSON.parse(result))
        .catch(error => console.error('error', error));
    return content[page]?.content?.rendered
}