import { useRef, useEffect } from "react"
import { useControls, useGame} from "../../../store"
import { constants } from "../../../constants"
import gsap from 'gsap'

function PointLight(props){
    //stored values
    const levelIndex = useGame((state) => state.levelIndex)
    const game = useGame((state) => state.game)
    
    //define carlights colors
    let color
    if (props.type === "back"){
        color = game ? constants.CAR_GAME_BREAKLIGHTS_COLOR[levelIndex] : constants.CAR_BREAKLIGHTS_COLOR
    }
    else
    {
        color = game ? constants.CAR_GAME_FRONTLIGHTS_COLOR[levelIndex] : constants.CAR_FRONTLIGHTS_COLOR
    }
    return (
        <group {...props} dispose={null}>
            <pointLight color={color} intensity={props.intensity} distance={2}/>
        </group>
    )
}

function CarLights(props){
    //stored values
    const key = useControls((state) => state.keyPressed)
    const carLateralSlideSpeed = useControls((state) => state.carLateralSlideSpeed)
    const carFrontSlideSpeed = useControls((state) => state.carFrontSlideSpeed)
    const carBreakingSlideSpeed = useControls((state) => state.carBreakingSlideSpeed)
    const backBreaksIntensity = useControls((state) => state.backBreaksIntensity)
    
    //refs
    const frontLights = useRef()
    const backLights = useRef()

    useEffect(() => {
        if (key === "right"){
            gsap.to(frontLights.current.position, {x: constants.CAR_RIGHT_POSITION, duration: carLateralSlideSpeed})
            gsap.to(backLights.current.position, {x: constants.CAR_RIGHT_POSITION, duration: carLateralSlideSpeed})
        }
        if (key === "left"){
            gsap.to(frontLights.current.position, {x: constants.CAR_LEFT_POSITION, duration: carLateralSlideSpeed})
            gsap.to(backLights.current.position, {x: constants.CAR_LEFT_POSITION, duration: carLateralSlideSpeed})
        }
        if (key === "up"){
            gsap.to(frontLights.current.position, {z: constants.CAR_ACCELERATING_POSITION, duration: carFrontSlideSpeed})
            gsap.to(backLights.current.position, {z: constants.CAR_ACCELERATING_POSITION, duration: carFrontSlideSpeed})
        }
        if (key === "down"){
            gsap.to(frontLights.current.position, {z: constants.CAR_BREAKING_POSITION, duration: carBreakingSlideSpeed})
            gsap.to(backLights.current.position, {z: constants.CAR_BREAKING_POSITION, duration: carBreakingSlideSpeed})
            if (frontLights.current.position.z === constants.CAR_ACCELERATING_POSITION){
                gsap.to(frontLights.current.position, {z: constants.CAR_BREAKING_POSITION, duration: carBreakingSlideSpeed})
                gsap.to(backLights.current.position, {z: constants.CAR_BREAKING_POSITION, duration: carBreakingSlideSpeed})
            }
            else {
                gsap.timeline()
                .to(frontLights.current.position, {z: constants.CAR_BREAKING_POSITION + 0.5, duration: carBreakingSlideSpeed})
                .to(frontLights.current.position, {z: constants.CAR_BREAKING_POSITION, duration: carBreakingSlideSpeed})
                gsap.timeline()
                .to(backLights.current.position, {z: constants.CAR_BREAKING_POSITION + 0.5, duration: carBreakingSlideSpeed})
                .to(backLights.current.position, {z: constants.CAR_BREAKING_POSITION, duration: carBreakingSlideSpeed})
                gsap.timeline()
                .to(backLights.current.children, {intensity: 1, duration: carBreakingSlideSpeed})
                .to(backLights.current.children, {intensity: 0, duration: carBreakingSlideSpeed})
            }
        }
    })

    return (
        <group {...props}>
            <group ref={frontLights}>
                <PointLight position-x={-0.5} position-z={-2.5} type="front" intensity={1} distance={2}/>
                <PointLight position-x={0.5} position-z={-2.5} type="front" intensity={1} distance={2}/>
            </group>
            <group ref={backLights}>
                <PointLight position-x={-0.5} position-z={1.5} type="back" intensity={backBreaksIntensity} distance={2}/>
                <PointLight position-x={0.5} position-z={1.5} type="back" intensity={backBreaksIntensity} distance={2}/>
            </group>
        </group>
    )
}

export default CarLights