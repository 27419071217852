import { useEffect } from 'react';
import {useControls} from '../../store';
import { constants } from '../../constants';

const KeyboardEvents = () => {
  //stored functions
  const setKey = useControls((state) => state.setKey); 
  const setHighSpeed = useControls((state) => state.setHighSpeed); 
  const setLowSpeed = useControls((state) => state.setLowSpeed); 
  const setRadio = useControls((state) => state.setRadio); 

  useEffect(() => {
    const handleEsc = (event) => {
    if (event.keyCode === 37 || event.keyCode === 81) {
      setKey(constants.LEFT)
    }
    else if (event.keyCode === 39|| event.keyCode === 68) {
      setKey(constants.RIGHT)
    }
    else if (event.keyCode === 38 || event.keyCode === 90) {
      setKey(constants.UP)
      setHighSpeed()
    }
    else if (event.keyCode === 40 || event.keyCode === 83) {
      setKey(constants.DOWN)
      setLowSpeed()
    }
    else if (event.keyCode === 82) {
      setRadio()
    }
  };

    window.addEventListener(constants.TRIGGER_EVENT, handleEsc);

  return () => {
      window.removeEventListener(constants.TRIGGER_EVENT, handleEsc);
  };
}, []);

return(<></>);
}

export default KeyboardEvents

