import { extend, useFrame } from "@react-three/fiber"
import { shaderMaterial } from "@react-three/drei"
import { useRef } from "react"
import glsl from "babel-plugin-glsl/macro"
import {Color, Texture} from "three"
import { constants } from "../../../constants"
import { useGame } from "../../../store"

const BeachShaderMaterial = shaderMaterial(
    //Uniform
    {
        uTime: 0,
        uColor: new Color(0.0, 0.0, 0.0),
        uTexture: new Texture()
    },
    //Vertex shader
    glsl`
        precision mediump float;

        uniform float uTime;

        #pragma glslify: snoise3 = require(glsl-noise/simplex/3d.glsl);

        varying vec2 vUv;

        void main() {
            vUv = uv;

            vec3 pos = position;
            float noiseFreq = 1.5;
            float noiseAmp = 0.15;
            vec3 noisePos = vec3(pos.y * noiseFreq + uTime, pos.y, pos.z);
            pos.z += snoise3(noisePos) * noiseAmp;

            gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
        }
    `,
    //Fragment shader
    glsl`
        precision mediump float;

        uniform vec3 uColor;
        uniform float uTime;

        varying vec2 vUv;

        void main() {
            gl_FragColor = vec4(uColor, 1.0); 
        }
    `
)

extend({BeachShaderMaterial})

const Beach = (props) => {
    //stored values
    const game = useGame((state) => state.game)
    const levelIndex = useGame((state) => state.levelIndex)
    //refs
    const ref = useRef()

    useFrame(({clock}) => ref.current.uTime = clock.getElapsedTime())
    return (
        <group {...props} dispose={null}>
            <mesh
                rotation-x={-Math.PI/2}
                rotation-y={-0.4}
            >
                <planeGeometry args={[2, 100, 32, 34]} />
                <beachShaderMaterial uColor={game ? constants.BEACH_GAME_COLOR[levelIndex] : constants.BEACH_COLOR} ref={ref}/>
            </mesh>
        </group>
    )
}

export default Beach