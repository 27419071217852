import {useFrame} from "@react-three/fiber"
import {useRef, useEffect} from "react"
import Tree from "./tree/Tree"
import {useControls} from "../../../store"

function Trees(props){
    const ref = useRef()
    const speed = useControls((state) => state.speed)

    const getRandomInt = (int) => {
        return Math.floor(Math.random() * int) + 2
    }

    useFrame(() => {
        for (let tree of ref.current.children){
            tree.position.z += speed
            if (tree.position.z > 50){
                tree.position.z = -50
            }
        }
    })

    useEffect(() => {
        for (let tree of ref.current.children){
            let scale = getRandomInt(3)/7
            tree.scale.y = scale
            tree.scale.x = scale
            tree.scale.z = scale
            tree.rotation.y = -Math.PI/getRandomInt(4)
        }
    }, []);

    return (
        <group ref={ref} {...props} dispose={null}>
            {[...Array(10)].map((x, i) => {                
                return(
                   <group position-z={i*10 - 50} key={i}>
                        <Tree/>
                   </group>
                )
                }
            )}
        </group>
    )
}

export default Trees