import styles from "./RadioPlayer.module.scss";
import {Howl} from 'howler';
import {useControls, /* useCamera */} from '../../store';
import { useEffect, useRef } from "react";
import gsap from "gsap";
import { constants } from "../../constants";

const sound = new Howl({
    src: constants.RADIO_URL,
    html5: true,
    onload: () => {
		sound.fade(0, 1, 5000);
	}
});

function RadioPlayer(props){
    //stored values
    const radio = useControls((state) => state.radio)

    //refs
    const radioPlayer = useRef()
    
    useEffect(()=>{
        if (radio){
            sound.play()
            gsap.to(radioPlayer.current, {left: 0, duration: 0.5})
        }
        else
        {
            sound.pause()
            gsap.to(radioPlayer.current, {left: -500, duration: 0.5})
        }
    })
    
    return(
        <div ref={radioPlayer} className="radioPlayer">
            <div className={styles.playerContainer}>
                <h3 className={styles.title}>Visit NightRide FM</h3>
                <div className={styles.advertisement}>
                    Radio streamed from NightRide.fm,<br/>
                    on <a className={styles.radioLink} target="_blank" href="https://nightride.fm">https://nightride.fm</a>
                </div>
            </div>
        </div>
    )
}

export default RadioPlayer