import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";
import { useEffect, useRef } from "react";
import About from "../../views/about/About"
import Contact from "../../views/contact/Contact"
import Home from "../../views/home/Home"
import styles from "./Nav.module.scss"
import gsap from "gsap"
import { constants } from "../../constants";
import { useGame, useControls } from "../../store";

function Nav(props){
    //stored functions
    const startPlaying = useGame((state) => state.startPlaying)
    const displayMenus = useControls((state) => state.displayMenus)
    //stored values
    const menus = useControls((state) => state.menus)
    const navMenu = useRef()

    useEffect(()=>{
        if(menus) gsap.to(navMenu.current, {left: 0, duration: 0.5})
        if(!menus) gsap.to(navMenu.current, {left: -400, duration: 0.5})
    })
    function play(){
        startPlaying()
        displayMenus(false)
    }
    return (
        <Router>
                <Routes>
                    <Route path="/" element={<Home/>} />
                    <Route path="/contact" element={<Contact/>} />
                    <Route path="/about" element={<About/>} />
                </Routes>
                <ul ref={navMenu} className={props.className}>
                    <li className={styles.listItem}>
                        <a onClick={play}>{constants.PLAY}</a>
                    </li>
                    <li className={styles.listItem}>
                        <Link to="/about">{constants.ABOUT}</Link>
                    </li>
                    <li className={styles.listItem}>
                        <Link to="/contact">{constants.CONTACT}</Link>
                    </li>
                </ul>
        </Router>
    )
}

export default Nav